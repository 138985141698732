"use client"

import { AnimatePresence, motion } from "framer-motion"
import {
  FC,
  HTMLAttributes,
  ReactElement,
  Suspense,
  memo,
  use,
  useEffect,
  useState,
} from "react"

type CountryEmojiProps = {
  children: string
} & HTMLAttributes<HTMLSpanElement>

const sentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Parses a country name into an emoji flag
 *
 * !IMPORTANT! - Must be wrapped in a `Suspense` component
 * or it will cause UI flashing due to using the `use` hook
 *
 * @param {children}
 */
export const CountryEmoji: FC<CountryEmojiProps> = ({ children, ...props }) => {
  if (typeof children !== "string") {
    return children as ReactElement
  }
  // !!! SPOOKY EXPERIMENTAL API !!!
  const countries: Record<string, string> = use(
    import("@/data/countries_flags.json").then((m) => m.default) // eslint-disable-line import/extensions
  )

  return (
    <span title={countries?.[children] && children} {...props}>
      {countries?.[sentenceCase(children)] || children}
    </span>
  )
}

export default memo(CountryEmoji)

const _FlagAnimator: FC<{ flags: string[]; delay?: number }> = ({
  flags,
  delay = 3_500,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((i) => (i + 1) % (flags?.length || 0))
    }, delay)

    return () => {
      clearInterval(timer)
    }
  }, [delay, flags?.length])

  return (
    <div className="relative">
      {/* This is just here for spacing */}
      <span aria-hidden="true" className="invisible">
        🇦🇺
      </span>
      <AnimatePresence>
        {flags?.map(
          (flag, index) =>
            index === currentIndex && (
              <motion.span
                key={flag}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Suspense>
                  <CountryEmoji className="absolute left-0">
                    {sentenceCase(flag)}
                  </CountryEmoji>
                </Suspense>
              </motion.span>
            )
        )}
      </AnimatePresence>
    </div>
  )
}

export const FlagAnimator = memo(_FlagAnimator)
