"use client"

import { FC, ReactNode } from "react"
import { usePathname } from "next/navigation"

import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion"

type OrientationSubmitHiderProps = {
  children: ReactNode
} & HTMLMotionProps<"div">
export const OrientationSubmitHider: FC<OrientationSubmitHiderProps> = ({
  children,
  ...props
}) => {
  const pathname = usePathname()
  return (
    <AnimatePresence>
      {!pathname.includes("passport") && (
        <motion.div
          style={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          {...props}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
export default OrientationSubmitHider
